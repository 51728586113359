import { default as action_45reference3Qjvypm8e6Meta } from "/vercel/path0/pages/action-reference.vue?macro=true";
import { default as _91slug_93Po03rnzIFtMeta } from "/vercel/path0/pages/blog/[slug].vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as featuresLse7A5xVDGMeta } from "/vercel/path0/pages/candidates/features.vue?macro=true";
import { default as indexXOzMy3lhAAMeta } from "/vercel/path0/pages/candidates/index.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as create_45memberMV1boHcO0yMeta } from "/vercel/path0/pages/create-member.vue?macro=true";
import { default as create_45ownerYCD4Y3wIAsMeta } from "/vercel/path0/pages/create-owner.vue?macro=true";
import { default as indexdV9FWWUuUFMeta } from "/vercel/path0/pages/dashboard/applications/index.vue?macro=true";
import { default as indexA8mkq8srhXMeta } from "/vercel/path0/pages/dashboard/archived-jobs/index.vue?macro=true";
import { default as _91id_93gOAaBmL4OuMeta } from "/vercel/path0/pages/dashboard/archived-jobs/repost/[id].vue?macro=true";
import { default as _91id_93ENfNdSZeo0Meta } from "/vercel/path0/pages/dashboard/archived-jobs/view/[id].vue?macro=true";
import { default as index1T8LT2NP01Meta } from "/vercel/path0/pages/dashboard/candidates/index.vue?macro=true";
import { default as index1L80cTfbFhMeta } from "/vercel/path0/pages/dashboard/chat/index.vue?macro=true";
import { default as indexlQA3dFMsunMeta } from "/vercel/path0/pages/dashboard/home/index.vue?macro=true";
import { default as createaC9Vvmaxj3Meta } from "/vercel/path0/pages/dashboard/jobs/create.vue?macro=true";
import { default as _91id_93rxCn6Tex31Meta } from "/vercel/path0/pages/dashboard/jobs/edit/[id].vue?macro=true";
import { default as indexZdqyYkiesAMeta } from "/vercel/path0/pages/dashboard/jobs/index.vue?macro=true";
import { default as _91id_93QD9hqTJGPmMeta } from "/vercel/path0/pages/dashboard/jobs/invite/[id].vue?macro=true";
import { default as _91id_93GZk4rMdi41Meta } from "/vercel/path0/pages/dashboard/jobs/view/[id].vue?macro=true";
import { default as indexzhdWy4k05PMeta } from "/vercel/path0/pages/dashboard/payments/index.vue?macro=true";
import { default as account89M4I59mRnMeta } from "/vercel/path0/pages/dashboard/settings/account.vue?macro=true";
import { default as billingEIJdN4IBPcMeta } from "/vercel/path0/pages/dashboard/settings/billing.vue?macro=true";
import { default as companyfSmyA8v9zpMeta } from "/vercel/path0/pages/dashboard/settings/company.vue?macro=true";
import { default as teamMdYlVVt4scMeta } from "/vercel/path0/pages/dashboard/settings/team.vue?macro=true";
import { default as settingsiT0n0ZleJjMeta } from "/vercel/path0/pages/dashboard/settings.vue?macro=true";
import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as browse_45candidates1SyhQ5hzkeMeta } from "/vercel/path0/pages/employers/browse-candidates.vue?macro=true";
import { default as featuresd6C8DepatZMeta } from "/vercel/path0/pages/employers/features.vue?macro=true";
import { default as indexBvO5URUDWLMeta } from "/vercel/path0/pages/employers/index.vue?macro=true";
import { default as pricingSeZziS9dlUMeta } from "/vercel/path0/pages/employers/pricing.vue?macro=true";
import { default as forgot_45password9RntqnVzd3Meta } from "/vercel/path0/pages/forgot-password.vue?macro=true";
import { default as how_45to_45delete_45accountBm46sZ84k1Meta } from "/vercel/path0/pages/how-to-delete-account.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as invalid_45linkYeDGlOdWa0Meta } from "/vercel/path0/pages/invalid-link.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as maintenanceLjk4bqrWMWMeta } from "/vercel/path0/pages/maintenance.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as reset_45password7wK2GPZgVKMeta } from "/vercel/path0/pages/reset-password.vue?macro=true";
import { default as terms_45and_45conditions0ynQwIk1xRMeta } from "/vercel/path0/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: action_45reference3Qjvypm8e6Meta?.name ?? "action-reference",
    path: action_45reference3Qjvypm8e6Meta?.path ?? "/action-reference",
    meta: action_45reference3Qjvypm8e6Meta || {},
    alias: action_45reference3Qjvypm8e6Meta?.alias || [],
    redirect: action_45reference3Qjvypm8e6Meta?.redirect,
    component: () => import("/vercel/path0/pages/action-reference.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Po03rnzIFtMeta?.name ?? "blog-slug",
    path: _91slug_93Po03rnzIFtMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93Po03rnzIFtMeta || {},
    alias: _91slug_93Po03rnzIFtMeta?.alias || [],
    redirect: _91slug_93Po03rnzIFtMeta?.redirect,
    component: () => import("/vercel/path0/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexmfxAx2eWMBMeta?.name ?? "blog",
    path: indexmfxAx2eWMBMeta?.path ?? "/blog",
    meta: indexmfxAx2eWMBMeta || {},
    alias: indexmfxAx2eWMBMeta?.alias || [],
    redirect: indexmfxAx2eWMBMeta?.redirect,
    component: () => import("/vercel/path0/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: featuresLse7A5xVDGMeta?.name ?? "candidates-features",
    path: featuresLse7A5xVDGMeta?.path ?? "/candidates/features",
    meta: featuresLse7A5xVDGMeta || {},
    alias: featuresLse7A5xVDGMeta?.alias || [],
    redirect: featuresLse7A5xVDGMeta?.redirect,
    component: () => import("/vercel/path0/pages/candidates/features.vue").then(m => m.default || m)
  },
  {
    name: indexXOzMy3lhAAMeta?.name ?? "candidates",
    path: indexXOzMy3lhAAMeta?.path ?? "/candidates",
    meta: indexXOzMy3lhAAMeta || {},
    alias: indexXOzMy3lhAAMeta?.alias || [],
    redirect: indexXOzMy3lhAAMeta?.redirect,
    component: () => import("/vercel/path0/pages/candidates/index.vue").then(m => m.default || m)
  },
  {
    name: confirm2uAIxw9fKZMeta?.name ?? "confirm",
    path: confirm2uAIxw9fKZMeta?.path ?? "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    alias: confirm2uAIxw9fKZMeta?.alias || [],
    redirect: confirm2uAIxw9fKZMeta?.redirect,
    component: () => import("/vercel/path0/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact",
    path: contactJnsDpYVejzMeta?.path ?? "/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: create_45memberMV1boHcO0yMeta?.name ?? "create-member",
    path: create_45memberMV1boHcO0yMeta?.path ?? "/create-member",
    meta: create_45memberMV1boHcO0yMeta || {},
    alias: create_45memberMV1boHcO0yMeta?.alias || [],
    redirect: create_45memberMV1boHcO0yMeta?.redirect,
    component: () => import("/vercel/path0/pages/create-member.vue").then(m => m.default || m)
  },
  {
    name: create_45ownerYCD4Y3wIAsMeta?.name ?? "create-owner",
    path: create_45ownerYCD4Y3wIAsMeta?.path ?? "/create-owner",
    meta: create_45ownerYCD4Y3wIAsMeta || {},
    alias: create_45ownerYCD4Y3wIAsMeta?.alias || [],
    redirect: create_45ownerYCD4Y3wIAsMeta?.redirect,
    component: () => import("/vercel/path0/pages/create-owner.vue").then(m => m.default || m)
  },
  {
    name: dashboardHdtnClvoGAMeta?.name ?? "dashboard",
    path: dashboardHdtnClvoGAMeta?.path ?? "/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    alias: dashboardHdtnClvoGAMeta?.alias || [],
    redirect: dashboardHdtnClvoGAMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: indexdV9FWWUuUFMeta?.name ?? "dashboard-applications",
    path: indexdV9FWWUuUFMeta?.path ?? "applications",
    meta: indexdV9FWWUuUFMeta || {},
    alias: indexdV9FWWUuUFMeta?.alias || [],
    redirect: indexdV9FWWUuUFMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/applications/index.vue").then(m => m.default || m)
  },
  {
    name: indexA8mkq8srhXMeta?.name ?? "dashboard-archived-jobs",
    path: indexA8mkq8srhXMeta?.path ?? "archived-jobs",
    meta: indexA8mkq8srhXMeta || {},
    alias: indexA8mkq8srhXMeta?.alias || [],
    redirect: indexA8mkq8srhXMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/archived-jobs/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93gOAaBmL4OuMeta?.name ?? "dashboard-archived-jobs-repost-id",
    path: _91id_93gOAaBmL4OuMeta?.path ?? "archived-jobs/repost/:id()",
    meta: _91id_93gOAaBmL4OuMeta || {},
    alias: _91id_93gOAaBmL4OuMeta?.alias || [],
    redirect: _91id_93gOAaBmL4OuMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/archived-jobs/repost/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ENfNdSZeo0Meta?.name ?? "dashboard-archived-jobs-view-id",
    path: _91id_93ENfNdSZeo0Meta?.path ?? "archived-jobs/view/:id()",
    meta: _91id_93ENfNdSZeo0Meta || {},
    alias: _91id_93ENfNdSZeo0Meta?.alias || [],
    redirect: _91id_93ENfNdSZeo0Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/archived-jobs/view/[id].vue").then(m => m.default || m)
  },
  {
    name: index1T8LT2NP01Meta?.name ?? "dashboard-candidates",
    path: index1T8LT2NP01Meta?.path ?? "candidates",
    meta: index1T8LT2NP01Meta || {},
    alias: index1T8LT2NP01Meta?.alias || [],
    redirect: index1T8LT2NP01Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/candidates/index.vue").then(m => m.default || m)
  },
  {
    name: index1L80cTfbFhMeta?.name ?? "dashboard-chat",
    path: index1L80cTfbFhMeta?.path ?? "chat",
    meta: index1L80cTfbFhMeta || {},
    alias: index1L80cTfbFhMeta?.alias || [],
    redirect: index1L80cTfbFhMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/chat/index.vue").then(m => m.default || m)
  },
  {
    name: indexlQA3dFMsunMeta?.name ?? "dashboard-home",
    path: indexlQA3dFMsunMeta?.path ?? "home",
    meta: indexlQA3dFMsunMeta || {},
    alias: indexlQA3dFMsunMeta?.alias || [],
    redirect: indexlQA3dFMsunMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/home/index.vue").then(m => m.default || m)
  },
  {
    name: createaC9Vvmaxj3Meta?.name ?? "dashboard-jobs-create",
    path: createaC9Vvmaxj3Meta?.path ?? "jobs/create",
    meta: createaC9Vvmaxj3Meta || {},
    alias: createaC9Vvmaxj3Meta?.alias || [],
    redirect: createaC9Vvmaxj3Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/jobs/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93rxCn6Tex31Meta?.name ?? "dashboard-jobs-edit-id",
    path: _91id_93rxCn6Tex31Meta?.path ?? "jobs/edit/:id()",
    meta: _91id_93rxCn6Tex31Meta || {},
    alias: _91id_93rxCn6Tex31Meta?.alias || [],
    redirect: _91id_93rxCn6Tex31Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/jobs/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZdqyYkiesAMeta?.name ?? "dashboard-jobs",
    path: indexZdqyYkiesAMeta?.path ?? "jobs",
    meta: indexZdqyYkiesAMeta || {},
    alias: indexZdqyYkiesAMeta?.alias || [],
    redirect: indexZdqyYkiesAMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QD9hqTJGPmMeta?.name ?? "dashboard-jobs-invite-id",
    path: _91id_93QD9hqTJGPmMeta?.path ?? "jobs/invite/:id()",
    meta: _91id_93QD9hqTJGPmMeta || {},
    alias: _91id_93QD9hqTJGPmMeta?.alias || [],
    redirect: _91id_93QD9hqTJGPmMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/jobs/invite/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93GZk4rMdi41Meta?.name ?? "dashboard-jobs-view-id",
    path: _91id_93GZk4rMdi41Meta?.path ?? "jobs/view/:id()",
    meta: _91id_93GZk4rMdi41Meta || {},
    alias: _91id_93GZk4rMdi41Meta?.alias || [],
    redirect: _91id_93GZk4rMdi41Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/jobs/view/[id].vue").then(m => m.default || m)
  },
  {
    name: indexzhdWy4k05PMeta?.name ?? "dashboard-payments",
    path: indexzhdWy4k05PMeta?.path ?? "payments",
    meta: indexzhdWy4k05PMeta || {},
    alias: indexzhdWy4k05PMeta?.alias || [],
    redirect: indexzhdWy4k05PMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/payments/index.vue").then(m => m.default || m)
  },
  {
    name: settingsiT0n0ZleJjMeta?.name ?? "dashboard-settings",
    path: settingsiT0n0ZleJjMeta?.path ?? "settings",
    meta: settingsiT0n0ZleJjMeta || {},
    alias: settingsiT0n0ZleJjMeta?.alias || [],
    redirect: settingsiT0n0ZleJjMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/settings.vue").then(m => m.default || m),
    children: [
  {
    name: account89M4I59mRnMeta?.name ?? "dashboard-settings-account",
    path: account89M4I59mRnMeta?.path ?? "account",
    meta: account89M4I59mRnMeta || {},
    alias: account89M4I59mRnMeta?.alias || [],
    redirect: account89M4I59mRnMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/settings/account.vue").then(m => m.default || m)
  },
  {
    name: billingEIJdN4IBPcMeta?.name ?? "dashboard-settings-billing",
    path: billingEIJdN4IBPcMeta?.path ?? "billing",
    meta: billingEIJdN4IBPcMeta || {},
    alias: billingEIJdN4IBPcMeta?.alias || [],
    redirect: billingEIJdN4IBPcMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: companyfSmyA8v9zpMeta?.name ?? "dashboard-settings-company",
    path: companyfSmyA8v9zpMeta?.path ?? "company",
    meta: companyfSmyA8v9zpMeta || {},
    alias: companyfSmyA8v9zpMeta?.alias || [],
    redirect: companyfSmyA8v9zpMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/settings/company.vue").then(m => m.default || m)
  },
  {
    name: teamMdYlVVt4scMeta?.name ?? "dashboard-settings-team",
    path: teamMdYlVVt4scMeta?.path ?? "team",
    meta: teamMdYlVVt4scMeta || {},
    alias: teamMdYlVVt4scMeta?.alias || [],
    redirect: teamMdYlVVt4scMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/settings/team.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: browse_45candidates1SyhQ5hzkeMeta?.name ?? "employers-browse-candidates",
    path: browse_45candidates1SyhQ5hzkeMeta?.path ?? "/employers/browse-candidates",
    meta: browse_45candidates1SyhQ5hzkeMeta || {},
    alias: browse_45candidates1SyhQ5hzkeMeta?.alias || [],
    redirect: browse_45candidates1SyhQ5hzkeMeta?.redirect,
    component: () => import("/vercel/path0/pages/employers/browse-candidates.vue").then(m => m.default || m)
  },
  {
    name: featuresd6C8DepatZMeta?.name ?? "employers-features",
    path: featuresd6C8DepatZMeta?.path ?? "/employers/features",
    meta: featuresd6C8DepatZMeta || {},
    alias: featuresd6C8DepatZMeta?.alias || [],
    redirect: featuresd6C8DepatZMeta?.redirect,
    component: () => import("/vercel/path0/pages/employers/features.vue").then(m => m.default || m)
  },
  {
    name: indexBvO5URUDWLMeta?.name ?? "employers",
    path: indexBvO5URUDWLMeta?.path ?? "/employers",
    meta: indexBvO5URUDWLMeta || {},
    alias: indexBvO5URUDWLMeta?.alias || [],
    redirect: indexBvO5URUDWLMeta?.redirect,
    component: () => import("/vercel/path0/pages/employers/index.vue").then(m => m.default || m)
  },
  {
    name: pricingSeZziS9dlUMeta?.name ?? "employers-pricing",
    path: pricingSeZziS9dlUMeta?.path ?? "/employers/pricing",
    meta: pricingSeZziS9dlUMeta || {},
    alias: pricingSeZziS9dlUMeta?.alias || [],
    redirect: pricingSeZziS9dlUMeta?.redirect,
    component: () => import("/vercel/path0/pages/employers/pricing.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password9RntqnVzd3Meta?.name ?? "forgot-password",
    path: forgot_45password9RntqnVzd3Meta?.path ?? "/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    alias: forgot_45password9RntqnVzd3Meta?.alias || [],
    redirect: forgot_45password9RntqnVzd3Meta?.redirect,
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: how_45to_45delete_45accountBm46sZ84k1Meta?.name ?? "how-to-delete-account",
    path: how_45to_45delete_45accountBm46sZ84k1Meta?.path ?? "/how-to-delete-account",
    meta: how_45to_45delete_45accountBm46sZ84k1Meta || {},
    alias: how_45to_45delete_45accountBm46sZ84k1Meta?.alias || [],
    redirect: how_45to_45delete_45accountBm46sZ84k1Meta?.redirect,
    component: () => import("/vercel/path0/pages/how-to-delete-account.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: invalid_45linkYeDGlOdWa0Meta?.name ?? "invalid-link",
    path: invalid_45linkYeDGlOdWa0Meta?.path ?? "/invalid-link",
    meta: invalid_45linkYeDGlOdWa0Meta || {},
    alias: invalid_45linkYeDGlOdWa0Meta?.alias || [],
    redirect: invalid_45linkYeDGlOdWa0Meta?.redirect,
    component: () => import("/vercel/path0/pages/invalid-link.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: maintenanceLjk4bqrWMWMeta?.name ?? "maintenance",
    path: maintenanceLjk4bqrWMWMeta?.path ?? "/maintenance",
    meta: maintenanceLjk4bqrWMWMeta || {},
    alias: maintenanceLjk4bqrWMWMeta?.alias || [],
    redirect: maintenanceLjk4bqrWMWMeta?.redirect,
    component: () => import("/vercel/path0/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQl0qlywOJTMeta?.name ?? "privacy-policy",
    path: privacy_45policyQl0qlywOJTMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyQl0qlywOJTMeta || {},
    alias: privacy_45policyQl0qlywOJTMeta?.alias || [],
    redirect: privacy_45policyQl0qlywOJTMeta?.redirect,
    component: () => import("/vercel/path0/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registerIikvtlfpbHMeta?.name ?? "register",
    path: registerIikvtlfpbHMeta?.path ?? "/register",
    meta: registerIikvtlfpbHMeta || {},
    alias: registerIikvtlfpbHMeta?.alias || [],
    redirect: registerIikvtlfpbHMeta?.redirect,
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45password7wK2GPZgVKMeta?.name ?? "reset-password",
    path: reset_45password7wK2GPZgVKMeta?.path ?? "/reset-password",
    meta: reset_45password7wK2GPZgVKMeta || {},
    alias: reset_45password7wK2GPZgVKMeta?.alias || [],
    redirect: reset_45password7wK2GPZgVKMeta?.redirect,
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditions0ynQwIk1xRMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditions0ynQwIk1xRMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditions0ynQwIk1xRMeta || {},
    alias: terms_45and_45conditions0ynQwIk1xRMeta?.alias || [],
    redirect: terms_45and_45conditions0ynQwIk1xRMeta?.redirect,
    component: () => import("/vercel/path0/pages/terms-and-conditions.vue").then(m => m.default || m)
  }
]